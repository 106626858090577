.App{
    width: 100vw;
    height: 100 vh;
}

body{
    margin:0;
    padding:0;
}


@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(./fonts/Poppins/Poppins-Regular.otf) format('truetype');
  }

@font-face {
    font-family: 'Avenir LT Pro';
    src: local('Avenir LT Pro'), url(./fonts/Avenir-LT-Pro/AvenirLTProBook.otf) format('truetype');
    font-weight: Regular;
  }

@font-face {
    font-family: 'Avenir LT Pro';
    src: local('Avenir LT Pro'), url(./fonts/Avenir-LT-Pro/AvenirLTProLight.otf) format('truetype');
    font-weight: Light;
  }
  @font-face {
    font-family: 'Avenir LT Pro';
    src: local('Avenir LT Pro'), url(./fonts/Avenir-LT-Pro/AvenirLTProMedium.otf) format('truetype');
    font-weight: 95Black;
  }