.App{
    width: 100vw;
    height: 100 vh;
}

body{
    margin:0;
    padding:0;
}


@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(../../static/media/Poppins-Regular.de2dd933.otf) format('truetype');
  }

@font-face {
    font-family: 'Avenir LT Pro';
    src: local('Avenir LT Pro'), url(../../static/media/AvenirLTProBook.e899c1f1.otf) format('truetype');
    font-weight: Regular;
  }

@font-face {
    font-family: 'Avenir LT Pro';
    src: local('Avenir LT Pro'), url(../../static/media/AvenirLTProLight.20789885.otf) format('truetype');
    font-weight: Light;
  }
  @font-face {
    font-family: 'Avenir LT Pro';
    src: local('Avenir LT Pro'), url(../../static/media/AvenirLTProMedium.c4e0137c.otf) format('truetype');
    font-weight: 95Black;
  }
